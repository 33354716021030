// @flow
import React from 'react'
import styled from 'styled-components'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import { getMagazineListPath } from 'routes'
import MagazineArticleWidget from 'theme/molecules/MagazineArticleWidget'
import Container from 'theme/atoms/Container'
import { ms } from 'modules/browser/const'
import Pagination from 'theme/molecules/Pagination'
import Meta from 'theme/atoms/Meta'
import translations from 'config/translations'
import {BannerProvider} from 'hooks/useBanner'

type Props = {
  location: {
    pathname: string
    key: string
  }
  pageContext: {
    category: string
  }
  data: {
    staticBlock: {
      story?: any
    }
    allMagazineArticle: {
      pageInfo: {
        hasNextPage: boolean
        hasPreviousPage: boolean
        currentPage: number
        pageCount: number
      }
      nodes: {
        urlKey: string
        teaserImageUrl: string
        title: string
        teaser: string
        categoryName: string
        sponsoredArticle: boolean
        base64: string
      }[]
    }
    banner: {
      title:string
      objectID:string
      popupCtaLabel:string
      popupCtaUrl:string
      popupDescription:string
      popupHintText:string
      popupTitle:string
      subtitle:string
      backgroundColor: string
    } | null
  }
}

export default function MagazineList(props: Props) {
  let story: any = null
  if (props.data.staticBlock && props.data.staticBlock.story) {
    story = props.data.staticBlock.story
  }
  const { pageInfo, nodes } = props.data.allMagazineArticle
  const { category } = props.pageContext

  return (
    <BannerProvider banner={props.data.banner}>
      <Layout partialStateUpdates={story && story.partialStateUpdates}>
        <Meta
          type="website"
          pathname={props.location.pathname}
          title={translations.meta.magazineList.title}
          description={translations.meta.magazineList.description}
          prevPath={
            pageInfo.hasPreviousPage && getMagazineListPath(pageInfo.currentPage - 2, category)
          }
          nextPath={pageInfo.hasNextPage && getMagazineListPath(pageInfo.currentPage, category)}
        />

        <Title>
          <h1>Dein kurviges Magazin für Mode in großen Größen</h1>
          <p>
            Dein Plus-Size-Magazin mit vielen Mode-Tipps & Inspirationen für mittlere/große Größen.
            Wir helfen Dir, Deine schönen Kurven am besten zur Geltung zu bringen!
          </p>
        </Title>

        {story && (
          // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
          <Story key={props.location.key} story={story} />
        )}

        <MagazineArticleWidget.Collection title="Alle Artikel" articles={nodes} />

        <PaginationWrapper>
          <Pagination
            page={pageInfo.currentPage - 1}
            totalPages={pageInfo.pageCount}
            resolvePath={(page) => getMagazineListPath(page, category)}
          />
        </PaginationWrapper>
      </Layout>
    </BannerProvider>
  )
}

const Title = styled(Container)`
  text-align: center;
  margin-top: 40px;
  > h1 {
    text-align: center;
    color: #262626;
    font-family: 'Cormorant Garamond';
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 34px;

    @media (min-width: ${ms.LAPTOP}px) {
      font-size: 36px;
      letter-spacing: 0.7px;
      line-height: 48px;
    }
  }
  > p {
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    font-weight: 400;
    font-style: normal;
    @media (min-width: ${ms.LAPTOP_L}px) {
      font-size: 18px;
      line-height: 1.58;
      letter-spacing: -0.003em;
    }
  }
`

const PaginationWrapper = styled.div`
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
`

export const query = graphql`
  query(
    $noStory: Boolean!
    $hpp: Int!
    $skip: Int!
    $categoryRegex: String!
    $categoryIdentifier: String!
  ) {
    staticBlock(identifier: { eq: $categoryIdentifier }) {
      story @skip(if: $noStory)
    }
    allMagazineArticle(
      limit: $hpp
      skip: $skip
      filter: { categoryName: { regex: $categoryRegex } }
      sort: {order: DESC, fields: updatedAt}
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
      nodes {
        urlKey
        categoryName
        teaserImageUrl
        title
        teaser
        categoryName
        sponsoredArticle
        base64(fieldName: "teaserImageUrl")
      }
    }
    banner(displayOnContentPages: {eq: true}) {
      title
      objectID
      popupCtaLabel
      popupCtaUrl
      popupDescription
      popupHintText
      popupTitle
      subtitle
      backgroundColor
    }
  }
`
